/*! {!project_name!} v0.1.0 */
/**
 * JS for: {!project_name!}
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 *
 * Modules
 * jQuery document ready
 * 
 * @todo Add each module/section needed
 * 
 */
/* Modules */
const $ = require('jquery');
const Swiper = require('swiper');
var scrollMonitor = require("scrollmonitor");
//var simpleParallax = require("simple-parallax-js");

//const swiper = require('swiper');

/* jQuery document ready */
//var image = document.getElementsByClassName('thumbnail');
//new simpleParallax(image);

/*var images = document.querySelectorAll('.thumbnail');
new simpleParallax(images, {
    //delay: 0,
    orientation: 'down',
    scale: 1.3,
    overfow: true
});
*/
var simpleParallax = require("simple-parallax-js");

//const swiper = require('swiper');

/* jQuery document ready */
var image = document.getElementsByClassName('thumbnail');
new simpleParallax(image);


function scrollTo(selector, offset, duration)
{
	scroll_position = $('' + selector + '').offset().top + offset;
	$('html, body').animate({scrollTop: scroll_position }, duration);
}
 
/**
 * recaptcha_callback
 *
 */
function recaptcha_callback()
{
		$('#form-contact').submit();
}
window.recaptcha_callback = recaptcha_callback;

function toggle_content()
{ 
	$('.certificados').hide();
	$('.membresia').hide();
	//var template = $('#toogle-link-template').html();
	//$('.practicas h4').append(template);
	$('.toogle-link').html(' ' + $('.toogle-link').data('label-show') + ' ').on('click', function()
		{
			if ( $(this).parent().is(':visible') ) { 
				
				
				$('.certificados').slideUp();
				$('.membresia').slideUp();
				 $('.contenedor-certificado-membresia')[0].style.height="250px";
        $('.contenedor-certificado-membresia2')[0].style.height="250px";

				//$('.practicas_content_movil').slideUp();
				
			//	$(this).html('' + $(this).data('label-show') + '');
			//	$(this).parent().removeClass( "practicas-ocultas" );
			} /*else { 
				$(this).parent().slideDown();
				$(this).html('[ ' + $(this).data('label-hide') + ' ]');
			}*/
		});


}

function certificados(id_contenedor)
{ 
  		
        $('.certificados').slideUp().delay(600);
        $('#'+id_contenedor).slideDown();
        $('.membresia').slideUp();
        $('.contenedor-certificado-membresia')[0].style.height="auto";
        $('.contenedor-certificado-membresia2')[0].style.height="auto";
     //alert($('.contenedor-certificado-membresia').style.height);
}

function membresia(id_contenedor)
{ 
  		
        $('.membresia').slideUp().delay(600);
        $('#'+id_contenedor).slideDown();
        $('.certificados').slideUp();
        $('.contenedor-certificado-membresia')[0].style.height="auto";
        $('.contenedor-certificado-membresia2')[0].style.height="auto";
       // alert($('.contenedor-certificado-membresia').style.height);
     
}



$(document).ready(function()
{toggle_content();
	
$('#button-submit').click(function(e)
	{
		e.preventDefault();
		if ('' != $('#input-name').val() && 
				'' != $('#input-telephone').val() &&
				/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test($('#input-email').val()) &&
				'' != $('#input-comments').val()
				) {
			grecaptcha.execute({action: 'homepage'});
		} else {
			$('body').prepend('<input type="checkbox" id="check-notification-slide" name="check-notification-slide" class="check-notification slide hidden"><div class="notification warning slide clearfix"><p class="float-l">Verifica que hayas llenado todos los campos correctamente.</p><p class="float-r"><label for="check-notification-slide" id="label-notification-slide" title="Hide" class="float-r">cerrar</label></p></div>')
		}
	});



if (0 < $('.swiper-container.main').length) {
		var slider_loop = $('.swiper-container.main .swiper-slide ').length - 1;
		var swiperMain = new Swiper ('.swiper-container.main', {
		    direction: 'horizontal',
		    slidesPerview: 1,
		    loop: slider_loop,
		    watchOverflow: true,
		    touchEventsTarget: 'wrapper',
		    /*shortSwipes: false,
			longSwipes: true,
			longSwipesRatio: 0.5,
			longSwipesMs: 1300000,*/
		    grabCursor: true, 
		    autoplay: true,
		    scrollbar: {
		    	hide: true,
		    },
		    navigation: {
			    nextEl: '.swiper-button-next',
			    prevEl: '.swiper-button-prev',
				},
				 pagination: {
		    	el: '.swiper-pagination',
		    	type: 'bullets',
		    	clickable: true,
		  	},
		  	keyboard: {
		    	enabled: true,
		    }
		});
	}
	/* The testimonials slider */
	if (0 < $('.swiper-container.testimonials').length) {
		var slider_loop = $('.swiper-container.testimonials .swiper-slide ').length - 1;
		var swiperTestimonials = new Swiper ('.swiper-container.testimonials', {
		    direction: 'horizontal',
		    slidesPerview: 1,
		    loop: slider_loop,
		    watchOverflow: true,
		    touchEventsTarget: 'wrapper',
		    grabCursor: true, 
		    autoplay: false,
		    scrollbar: {
		    	hide: true,
		    },
		    navigation: {
			    nextEl: '.swiper-button-next',
			    prevEl: '.swiper-button-prev',
				},
		  	keyboard: {
		    	enabled: true,
		    }
		});
	}
	/* The scroll monitor */
	var myElement = $('body');
	 
	var elementWatcher = scrollMonitor.create(myElement, -120);
	var y = $(document).scrollTop();
	if(y>=120){
		$('header').toggleClass('collapsed', this.isAboveViewport)
	}
	
	elementWatcher.stateChange(function() {
		$('header').toggleClass('collapsed', this.isAboveViewport)
	});
	scrollMonitor.update(); 


	$('.link-scroll-to').click(function(e)	
	{
		var offset = 780 < $(window).width() ? -76 : -96;
		scrollTo($(this).attr('href'), offset, 'slow');
		$('#check-menu-toggle').prop('checked', false);
		e.preventDefault();
		e.stopPropagation();
	});




	$( "#btn-certificados").click(function() {
  
  	certificados('certificados');
  	


  
});	


$( "#btn-membresia").click(function() {

	
		membresia('membresia');
  	

  
});

$( "#btn-derecho-corporativo").click(function() {
	if($(window).width()<=780){

		practicas_content('derecho-corporativo-movil');

		
	}
	else{
    

     practicas_content('derecho-corporativo');
}
});

$( "#btn-propiedad-industrial").click(function() {
	if($(window).width()<=780){
		practicas_content('propiedad-industrial-intelectual-movil');
	
	}
		else{
     
     practicas_content('propiedad-industrial-intelectual');
 }
 
});

$( "#btn-derecho-inmobiliario").click(function() {
	if($(window).width()<=780){

		practicas_content('derecho-inmobiliario-movil');

}
else{		
   practicas_content('derecho-inmobiliario');
 }
 
});

$( "#btn-litigio-solucio-conflictos").click(function() {
	if($(window).width()<=780){

practicas_content('litigio-solucion-conflicto-movil');


	}
	else{
	practicas_content('litigio-solucion-conflicto');
    } 
  
});

$( "#btn-alianzas-servicios-coordinados").click(function() {
	if($(window).width()<=780){

		practicas_content('alianza-servicios-coordinados-movil');
		
	}
	else{
	
	practicas_content('alianza-servicios-coordinados');
 }
  
});

$( "#btn-pro-bono").click(function(){
	if($(window).width()<=780){


practicas_content('pro-bono-movil');
		
	}
	else{
	practicas_content('pro-bono');
 }
  
});


});
